exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cirujia-capilar-js": () => import("./../../../src/pages/cirujia-capilar.js" /* webpackChunkName: "component---src-pages-cirujia-capilar-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-estetica-js": () => import("./../../../src/pages/estetica.js" /* webpackChunkName: "component---src-pages-estetica-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

